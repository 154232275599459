<script setup lang="ts">
    import Task from '~~/app/task/models/Task';

    const props = defineProps({
  task: null,
  displayCheck: { type: Boolean, default: true },
  displayTitle: { type: Boolean, default: true },
  displayUsers: { type: Boolean, default: true },
  displayDueDate: { type: Boolean, default: true }
});

    const { onContextMenu } = useContextMenu({
        eventName: 'task:task:context-menu:open',
        payload: {
            model: props.task,
        },
    });
</script>

<template>
    <div class="@container">
        <div
            class="flex flex-col @sm:flex-row @sm:items-center @sm:justify-between px-4 py-3 space-y-4 @sm:space-y-0 @sm:space-x-4 bg-white hover:bg-neutral-50 active:bg-neutral-100 border border-gray-200 rounded-lg cursor-pointer transition-all"
            @click="task.preview()"
            @contextmenu="onContextMenu"
        >
            <div class="flex items-center @sm:flex-1 space-x-4">
                <TaskTaskItemCheck
                    v-if="displayCheck"
                    :task="task"
                />
                <TaskTaskItemTitle
                    v-if="displayTitle"
                    :task="task"
                />
            </div>
            <div
                v-if="displayUsers || displayDueDate"
                class="flex flex-col @sm:items-center space-y-4 @sm:flex-row @sm:flex-row-reverse @sm:space-x-4 @sm:space-x-reverse @sm:space-y-0"
            >
                <TaskTaskItemUsers
                    v-if="displayUsers"
                    :task="task"
                />
                <TaskTaskItemDueDate
                    v-if="displayDueDate"
                    :task="task"
                />
            </div>
        </div>
    </div>
</template>
